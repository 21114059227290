import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'

export enum ActionTypes {
    ADD_STYLE = 'add_style',
    UPDATE_STYLE = 'update_style',
    DELETE_STYLE = 'delete_style',
    GET_STYLES = 'get_styles',
    COUNT_STYLES = 'count_styles',
    GET_STYLE_BY_GUID = 'get_style_by_guid',
    GET_TYPES = 'get_types',
    GET_TYPE_PROPERTIES = 'get_type_properties'
}

export class AddStyle implements FluxStandardAction {
    type = 'Style/' + ActionTypes.ADD_STYLE
    constructor(public payload: StyleDTO){}
}

export class UpdateStyle implements FluxStandardAction {
    type = 'Style/' + ActionTypes.UPDATE_STYLE
    constructor(public payload: StyleDTO){}
}

export class DeleteStyle implements FluxStandardAction {
    type = 'Style/' + ActionTypes.DELETE_STYLE
    constructor(public guid: string){}
}

export class GetStyles implements FluxStandardAction {
    type = 'Style/' + ActionTypes.GET_STYLES
    constructor(public payload: string[][]){}
}

export class CountStyles implements FluxStandardAction {
    type = 'Style/' + ActionTypes.COUNT_STYLES
    constructor(public payload: string[][]){}
}

export class GetStyleByGuid implements FluxStandardAction {
    type = 'Style/' + ActionTypes.GET_STYLE_BY_GUID
    constructor(public guid: string){}
}

export class GetTypes implements FluxStandardAction {
    type = 'Style/' + ActionTypes.GET_TYPES
    constructor(){}
}

export class GetTypeProperties implements FluxStandardAction {
    type = 'Style/' + ActionTypes.GET_TYPE_PROPERTIES
    constructor(public val: string){}
}
